.a-deck{
    display: grid;
    grid-template-columns: repeat(auto-fill  ,minmax(500px, 1fr));
}

.item-details{
    font-size: 15px;
    font-weight: 500;
    color:black
}

.item-data{
    font-size: 14px;
    font-weight: 500;
    color:grey;
}
.order-details-float{
    float: right;
}
.card_retsro{
    min-height: 460px;
  }



